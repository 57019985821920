
export default {
  components: {
    CategoriesDropdownMeganav: () =>
      import('@/containers/theme/HomepageHeader/CategoriesDropdownMeganav.vue'),
  },
  props: {
    mode: {
      type: String,
      default: 'normal',
      validator: (value) => ['home', 'normal'].includes(value),
    },
  },
  data() {
    return {
      isDropdownActive: false,
      column1: {},
      column2: {},
      column3: {},
      categories: this.$t(`categories`) || [],
    }
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.isDropdownActive = false
      })
    },
  },
  methods: {
    mouseOverFunc(key) {
      const categories = this.categories.find(
        (category) => category.key === key
      )
      const meganavData = categories.subCategories
      this.column1 = meganavData.column1
      this.column2 = meganavData.column2
      this.column3 = meganavData.column3
    },
    hasSubCategories(category) {
      return category.subCategories.column1.length > 0
    },
  },
}
